<template>
  <div class="comment">
    <Row class="comment__wrapper"
         justify="start">
      <Column class="comment__avatar">
        <UserAvatar :params="{avatar: params.commenter.avatar, size: 50}"/>
      </Column>
      <Column class="comment__info">
        <Row class="comment__names">
          <span class="comment__name">{{params.commenter.name}}</span>
          <Row align="center">
            <Icon class="comment__icon"
                  xlink="answer"
                  viewport="0 0 20 20"/>
            <span class="comment__commented">{{commentedName}}</span>
          </Row>
        </Row>
        <div>
          <p class="comment__comment">{{params.comment}}</p>
        </div>
        <Row align="center">
          <Icon class="comment__icon"
                xlink="calender"
                viewport="0 0 20 20"/>
          <span
            class="comment__date">{{$moment.unix(params.created_at).format('DD MMMM YYYY')}}</span>
        </Row>
      </Column>
    </Row>
  </div>
</template>

<script>
export default {
  name: 'Comment',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  computed: {
    commentedName() {
      return this.$comments.find(item => item.id === this.params.parent_id).commenter.name;
    },
  },
};
</script>

<style lang="scss">
  @import "./src/assets/style/utilits/index";

  .comment {
    @include size(100%);
    padding: 0 0 0 74px;
    margin: 32px 0 0;

    &__wrapper {
      @include size(100%);
      border-top: 1px solid $border;
      padding: 32px 0 0;
    }

    &__avatar {
      padding: 0 20px 0 0;
    }

    &__info {
      @include size(calc(100% - 74px));
    }

    &__names {
      margin: 0 0 10px;
    }

    &__name {
      margin: 0 20px 0 0;
      @include font(16px, bold, 1.38);
    }

    &__icon {
      margin: 0 8px 0 0;
      fill: $border;
    }

    &__commented {
      @include font(14px, bold);
      color: rgba(0, 0, 0, .5);
    }

    &__comment {
      margin: 0 0 16px;
      @include font(16px, normal, 1.5);
      color: rgba(0, 0, 0, .58);
    }

    &__date {
      @include font(14px, normal, 1.57);
      color: rgba(0, 0, 0, .58);
    }
  }
</style>
